import React, { useState, useRef } from "react";

import "react-date-range/dist/styles.css";
import "../../assets/react-date-range.default.css";

import {
  DateRangePicker,
  defaultStaticRanges,
  createStaticRanges,
} from "react-date-range";
import { useClickOutside } from "./../../utils/hooks";

export default function DatePicker({ onChange, startDate, endDate }) {
  const [isEditing, setIsEditing] = useState(false);
  const ref = useRef();

  const allTimeStaticRange = [
    {
      label: "All Time",
      range: () => ({
        startDate: null,
        endDate: null,
      }),
    },
  ];

  const staticRanges = [
    ...createStaticRanges(allTimeStaticRange),
    ...defaultStaticRanges,
  ];

  const ranges = [
    {
      startDate: startDate || null,
      endDate: endDate || null,
      key: "selection",
    },
  ];

  const onClickOutside = () => {
    setIsEditing(false);
  };

  useClickOutside(ref, onClickOutside);

  const inputValue =
    startDate && endDate
      ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
      : "";

  return (
    <div className="relative" ref={ref}>
      <div>
        <label className="block font-medium text-sm/6">Date</label>
        <input
          type="text"
          className="block w-full px-3 py-1.5 sm:text-sm/6"
          onClick={() => setIsEditing(!isEditing)}
          value={inputValue}
          placeholder="Select date range"
          readOnly
        />
      </div>
      {isEditing && (
        <div className="absolute z-50 flex flex-col visible h-auto p-0 mt-3 transition-all duration-300 transform bg-white shadow-md opacity-100 top-full card">
          <div className="absolute top-0 w-0 h-0 transform -translate-y-full border-b-8 border-l-8 border-r-8 left-5 border-l-transparent border-r-transparent border-b-tone-500"></div>
          <div className="absolute top-[1px] w-0 h-0 left-5 transform  -translate-y-full border-b-8 border-l-8 border-r-8 border-l-transparent border-r-transparent border-b-white"></div>

          <DateRangePicker
            className="rounded-lg"
            onChange={onChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={ranges}
            direction="horizontal"
            rangeColors={["#30A689"]}
            staticRanges={staticRanges}
            inputRanges={[]}
          />
        </div>
      )}
    </div>
  );
}
