/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { Link } from "@reach/router";
import classNames from "./../../utils/classnames";

export default function OptionField({
  value,
  label,
  description,
  options,
  onChange,
}) {
  const handleOptionClick = (option) => {
    if (value === option?.value) {
      onChange(false); // Deselect if clicked again
    } else {
      onChange(option?.value); // Select the clicked option
    }
  };
  return (
    <RadioGroup value={value} options={options} className="">
      <h3 className="mb-3">{label}</h3>

      {description && (
        <div
          className="mb-4 -my-2 text-sm text-tone-900"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      )}

      <div className="flex w-full gap-10 h-36">
        {options.map((option) => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            onClick={() => handleOptionClick(option)}
            className={({ checked, active }) =>
              classNames(
                checked ? "border-transparent" : "border-tone-500",
                active ? "border-primary ring-2 ring-primary" : "",
                !option.disabled && "cursor-pointer",
                "relative bg-white border-gray-100 border flex-1 rounded-xl hover:border-gray-300 focus:border-primary focus:ring-primary px-6 pt-4 pb-4 flex cursor-pointer focus:outline-none",
              )
            }
          >
            {({ checked, active }) => (
              <>
                {option.disabled && (
                  <svg
                    className="z-10 absolute inset-0 bg-white opacity-50 h-full w-full stroke-tone-900/10"
                    fill="none"
                  >
                    <defs>
                      <pattern
                        id="pattern-d09edaee-fc6a-4f25-aca5-bf9f5f77e14a"
                        x="0"
                        y="0"
                        width="10"
                        height="10"
                        patternUnits="userSpaceOnUse"
                      >
                        <path d="M-3 13 15-5M-5 5l18-18M-1 21 17 3"></path>
                      </pattern>
                    </defs>
                    <rect
                      stroke="none"
                      fill="url(#pattern-d09edaee-fc6a-4f25-aca5-bf9f5f77e14a)"
                      width="100%"
                      height="100%"
                    ></rect>
                  </svg>
                )}
                <span className="flex flex-1">
                  <span className="flex flex-col flex-1">
                    <RadioGroup.Label
                      as="span"
                      className="flex items-center mb-2 font-medium text-md text-secondary"
                    >
                      {option.icon && (
                        <span className="mr-2 -ml-2">{option.icon}</span>
                      )}
                      <span className="flex-1 leading-tight">
                        {option.label}
                      </span>

                      <CheckCircleIcon
                        className={classNames(
                          !checked ? "invisible" : "",
                          "h-6 w-6 text-primary",
                        )}
                        aria-hidden="true"
                      />
                    </RadioGroup.Label>

                    <RadioGroup.Description
                      as="span"
                      className="flex flex-col gap-1 mt-1 text-sm text-gray-500"
                      dangerouslySetInnerHTML={{ __html: option.description }}
                    ></RadioGroup.Description>
                    {option.upgradeMessage && (
                      <div>
                        <Link
                          to="/billing"
                          className="absolute z-50 flex items-center gap-2 px-3 py-1 text-sm text-white transition-colors rounded-full bottom-2 left-2 hover:bg-amber-600 hover:text-white bg-amber-500 opacity-90"
                        >
                          <SparklesIcon className="w-5 h-5 -ml-1" />
                          Upgrade to unlock
                        </Link>
                      </div>
                    )}
                    {option.proMessage && (
                      <div className="absolute z-50 flex items-center gap-2 px-3 py-1 text-sm text-white transition-colors rounded-full bottom-2 left-2 bg-amber-500 opacity-90">
                        <SparklesIcon className="w-5 h-5 -ml-1" />
                        Pro
                      </div>
                    )}
                  </span>
                </span>
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-primary" : "border-transparent",
                    "absolute -inset-px rounded-lg pointer-events-none",
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
