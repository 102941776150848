import React from "react";
import { navigate } from "@reach/router";
import { DateTime } from "luxon";
import { QueueListIcon } from "@heroicons/react/24/outline";

import Config from "../config";
import Session from "../components/Session";
import LoadingState from "../components/states/LoadingState";
import Modal from "../components/modals/Modal";
import LogTable from "../components/tables/LogTable";
import fetchFromApi from "../utils/fetchFromApi";
import SelectMenu from "../components/fields/SelectMenu";
import DatePicker from "../components/fields/DatePicker";
const errorDefinitions = {
  save_error:
    "Couldn't save your line items to the deal - this is usually because the data isn't mapped correctly to your HubSpot line item properties.",
  filter_failed: "Didn't match the filters for this template",
  invalid_input: "Invalid input - check data.",
  over_limit:
    "You've reached the maximum number of runs for this billing period",
  template_inactive: "The template selected is currently set to inactive",
  feature_unavailable:
    "You do not have access to the workflows feature on your current plan",
  no_match: "This deal did not match the filters set in the template",
  template_workflows_disabled:
    "Workflow triggers are disabled for this template",
};

class Logs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      loading: false,
      error: false,
      errorDetails: false,
      limit: 100,
      skip: 0,
      dealId: "",
    };
  }

  componentDidMount = async () => {
    document.title = `Logs ${Config.titleSuffix}`;

    let user = await Session.getUserData();
    if (!user?.org) {
      navigate("/choose-organisation");
      return;
    }

    const searchParams = new URLSearchParams(this.props.location.search);
    const template = searchParams.get("template");
    const status = searchParams.get("status");

    this.setState({ template, status });

    await Promise.all([this.getOptions(), this.getLogs()]);

    this.setState({
      user: user,
      pageLoading: false,
      defaultTemplate: template,
      defaultStatus: status,
    });
  };

  async getOptions() {
    try {
      const path = `/logs/options`;
      const response = await fetchFromApi(path, this.props.location.pathname);
      this.setState({ templateOptions: response.templateOptions });
    } catch {
      this.setState({
        error: true,
        errorDetails: { title: "Could not fetch template options" },
      });
    }
  }

  async getLogs() {
    try {
      const { limit, skip } = this.state;

      let path = `/logs/get?limit=${limit}&skip=${skip}`;

      if (this.state?.template) {
        path += `&template=${this.state.template}`;
      }

      if (this.state?.status) {
        path += `&status=${this.state.status}`;
      }

      if (this.state?.dealId) {
        path += `&dealId=${this.state.dealId}`;
      }

      if (this.state?.startDate) {
        const startDate = encodeURIComponent(this.state.startDate.toISO());
        path += `&startDate=${startDate}`;
      }

      if (this.state?.endDate) {
        const endDate = encodeURIComponent(this.state.endDate.toISO());
        path += `&endDate=${endDate}`;
      }

      const response = await fetchFromApi(path, this.props.location.pathname);

      this.setState({ logs: response.logs, total: response.count });
    } catch {
      this.setState({
        error: true,
        errorDetails: { title: "Could not fetch logs" },
      });
    }
  }

  formatDatetime = (timestamp) => {
    const datetime = DateTime.fromISO(timestamp);
    return datetime.toFormat("D - TT");
  };

  formatSource = (source) => {
    switch (source) {
      case "workflow":
        return "Workflow";
      case "extension":
        return "LinePilot Button";
      case "webhook":
        return "Deal Create / Change";
      case "frontend":
        return "Template Preview";
      default:
        return "";
    }
  };

  formatOutput = (l) => {
    if (l.status === "success") {
      // The properties used to create the success output where only added after 2024-11-30 10:12:42.
      // So return an empty string for all logs before this date.
      const timestamp = DateTime.fromISO(l.timestamp);
      const propsAdded = DateTime.fromSQL("2024-11-30 10:12:42");

      if (timestamp < propsAdded) {
        return "";
      }

      const output = [];

      if (l.created_count) {
        if (l.created_count === 1) {
          output.push(`1 line item was created`);
        } else {
          output.push(`${l.created_count} line items were created`);
        }
      }

      if (l.updated_count) {
        if (l.updated_count === 1) {
          output.push(`1 line item was updated`);
        } else {
          output.push(`${l.updated_count} line items were updated`);
        }
      }

      if (l.updated_total) {
        output.push(`Deal total is now ${l.updated_total}`);
      }

      if (output.length) {
        return output.join(", ");
      } else {
        return "No line items were created or updated";
      }
    }

    if (l.status === "error") {
      if (l.error_code === "template_failed") {
        return "Could not get template";
      }

      if (errorDefinitions[l.error_code]) {
        return errorDefinitions[l.error_code];
      }

      return "";
    }
  };

  getDataForTable = () => {
    const logs = this.state?.logs || [];

    const data = logs.map((l) => {
      const datetime = this.formatDatetime(l.timestamp, l.timezone);
      const source = this.formatSource(l.source);
      const output = this.formatOutput(l);

      return {
        datetime: datetime,
        template: {
          name: l.template_name || "",
          link: l.template_name ? `template/${l.template_id}` : null,
        },
        status: l.status,
        deal: {
          id: l.deal_id,
          link:
            l.hubspot_domain && l.hub_portal
              ? `https://${encodeURIComponent(l.hubspot_domain)}/contacts/${l.hub_portal}/deal/${l.deal_id}`
              : null,
        },
        output: output,
        source: source,
      };
    });

    return data;
  };

  updatePagination({ skip }) {
    this.setState({ skip }, () => this.getLogs());
  }

  onTemplateChange(selectedOption) {
    const templateValue = selectedOption ? selectedOption.value : null;
    this.setState({ template: templateValue });
  }

  onStatusChange(selectedOption) {
    const statusValue = selectedOption ? selectedOption.value : null;
    this.setState({ status: statusValue });
  }

  onDealIdChange(e) {
    this.setState({ dealId: e.target.value });
  }

  onDateRangeChange({ selection }) {
    const startDate = selection?.startDate
      ? DateTime.fromJSDate(selection.startDate)
      : null;
    const endDate = selection?.endDate
      ? DateTime.fromJSDate(selection.endDate).endOf("day")
      : null;

    this.setState({ startDate, endDate });
  }

  filterLogs() {
    this.setState({ skip: 0 }, () => this.getLogs());
  }

  render() {
    const dataForTable = this.getDataForTable();
    const dealId = this.state.dealId;
    const statusOptions = [
      { label: "Success", value: "success" },
      { label: "Error", value: "error" },
    ];

    return (
      <div className="min-h-screen">
        {this.state.pageLoading ? (
          <LoadingState />
        ) : (
          <>
            <div className="flex flex-row h-screen max-w-screen-xl gap-10 mx-10 lg:mx-auto">
              <div className="flex-row flex-1 w-full">
                <header className="flex flex-row gap-5 pb-5 border-b mt-36 border-tone-500">
                  <div className="flex flex-col items-baseline flex-grow gap-1">
                    <div className="flex flex-row flex-grow w-full gap-5 items-top">
                      <h1>Logs</h1>
                    </div>
                  </div>
                </header>

                <div className="flex flex-col gap-5 mt-8 md:items-end md:flex-row">
                  <div className="flex-1">
                    <SelectMenu
                      label="Template"
                      options={this.state.templateOptions}
                      id="template"
                      name="template"
                      onChange={this.onTemplateChange.bind(this)}
                      defaultValue={this.state.templateOptions.find(
                        (t) => t.value === this.state.defaultTemplate,
                      )}
                    />
                  </div>
                  <div className="flex-1">
                    <DatePicker
                      onChange={this.onDateRangeChange.bind(this)}
                      startDate={this.state?.startDate?.toJSDate()}
                      endDate={this.state?.endDate?.toJSDate()}
                    />
                  </div>
                  <div className="flex-1">
                    <SelectMenu
                      label="Status"
                      options={statusOptions}
                      id="status"
                      name="status"
                      onChange={this.onStatusChange.bind(this)}
                      defaultValue={statusOptions.find(
                        (s) => s.value === this.state.defaultStatus,
                      )}
                    />
                  </div>
                  <div className="flex-1">
                    <label
                      htmlFor="dealId"
                      className="block font-medium text-sm/6"
                    >
                      Deal ID
                    </label>
                    <div className="">
                      <input
                        id="dealId"
                        name="dealId"
                        type="text"
                        placeholder="e.g. 32723686189"
                        className="block w-full px-3 py-1.5 sm:text-sm/6"
                        value={dealId}
                        onChange={this.onDealIdChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="flex-1">
                    <button
                      className="button-primary"
                      onClick={this.filterLogs.bind(this)}
                    >
                      Update Filters
                    </button>
                  </div>
                </div>
                {dataForTable.length > 0 ? (
                  <LogTable
                    data={{ data: dataForTable, skip: this.state.skip }}
                    rowCount={this.state?.total}
                    updatePagination={this.updatePagination.bind(this)}
                  />
                ) : (
                  <div className="w-full m-8 text-center">
                    <QueueListIcon className="w-24 h-24 mx-auto text-tone-500" />
                    <h2 className="font-medium text-lg/6">No logs found</h2>
                  </div>
                )}
              </div>
            </div>
            <Modal
              open={this.state?.error}
              type="error"
              title={this.state.errorDetails.title}
              message={this.state.errorDetails.message}
              setClose={() => {
                this.setState({ error: false }),
                  setTimeout(() => this.setState({ errorDetails: false }), 500);
              }}
            />
          </>
        )}
      </div>
    );
  }
}

export default Logs;
