/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import { Puff } from "svg-loaders-react";

export default function Message({
  open,
  title,
  message,
  setClose,
  type,
  icon,
}) {
  if (type === "loading") {
    icon = (
      <Puff className="stroke-primary" strokeOpacity="1" aria-hidden="true" />
    );
  }
  if (type === "success") {
    icon = <CheckCircleIcon className="text-primary h-7 w-11" />;
  }
  if (type === "error") {
    icon = (
      <ExclamationCircleIcon
        className="text-red-500 h-7 w-11"
        aria-hidden="true"
      />
    );
    message = (
      <>
        If the issue continues, reach out to our{" "}
        <a href="https://www.linepilot.co">support team</a>.
      </>
    );
  }

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 z-50 flex items-end justify-end p-6 px-4 py-6 pointer-events-none"
      >
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={open}
          as={Fragment}
          enter="transform ease-out duration-1000 transition"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-1000"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="z-10 w-full max-w-sm p-0 overflow-hidden shadow-lg pointer-events-auto mb-14 card">
            <div className="flex items-center w-full p-4">
              <div className="flex-shrink-0">{icon}</div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-left">{title}</p>
                {message && <p className="mt-1 text-sm ">{message}</p>}
              </div>
              {setClose && (
                <div className="flex flex-shrink-0 ml-4">
                  <button
                    className="p-1 button-icon"
                    onClick={() => {
                      setClose();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="w-5 h-5" aria-hidden="true" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
}
