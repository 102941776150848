import React from "react";
import { Link } from "@reach/router";
import { motion } from "framer-motion";
import Config from "../config";
import Session from "../components/Session";
import Confetti from "react-confetti";

class SubscriptionConfirmed extends React.Component {
  componentDidMount = async () => {
    document.title = `Subscription Confirmed ${Config.titleSuffix}`;
    await Session.refreshUserData();
  };

  render() {
    return (
      <motion.div
        key="3"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Confetti
          className="w-screen h-screen"
          colors={["#fbbf24", "#fcd34d", "#fde68a"]}
          numberOfPieces={1000}
          runTime={5000}
          recycle={false}
          drawShape={(ctx) => {
            ctx.beginPath();
            ctx.rect(-5, -5, 10, 10);
            ctx.fill();
            ctx.closePath();
          }}
        />
        <div className="flex flex-col items-center my-36">
          <h2 className="mt-6 text-2xl text-center">Subscription Confirmed</h2>

          <div className="flex flex-col max-w-lg gap-6 mx-auto text-center">
            <h3 className="max-w-sm mx-auto font-medium text-center text-green-800">
              Thank you - we really appreciate your business!
            </h3>

            <p className="max-w-lg mx-auto text-center">
              You can support us further, and help other HubSpot users find us,
              by leaving a review on the HubSpot App Marketplace
            </p>
            <div>
              <a
                className="button-primary"
                href="https://app.hubspot.com/l/ecosystem/marketplace/apps/linepilot-automated-line-items/write-review?eco_review_source=provider"
              >
                Leave us a review
              </a>
            </div>
            <div>
              <Link className="" to="/?billingredirect=true">
                Not right now
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }
}

export default SubscriptionConfirmed;
