import React from "react";
import classNames from "../utils/classnames";

export default function BookCall({ onboarding, updateOnboarding }) {
  return (
    <div
      className={classNames("w-full flex flex-col items-center justify-center")}
    >
      <div className="flex flex-col items-center w-full text-center">
        <iframe
          className="w-[620px] h-[620px] shadow-none border-none"
          src={
            "https://meetings.hubspot.com/hbevan/linepilot-book-onboarding?embed=true"
          }
        />
        <p>
          (Optional) Let our team walk through your requirements and help you
          find the best way to configure LinePilot for your needs.
        </p>
      </div>
      {onboarding && (
        <div className="mt-4 mb-20">
          <button
            onClick={() => updateOnboarding(4)}
            className="button-primary"
          >
            Skip this step
          </button>
        </div>
      )}
    </div>
  );
}
