import React from "react";
import { Redirect, navigate } from "@reach/router";

import Survey from "../components/Survey";
import HubSpot from "../components/HubSpot";
import BookCall from "../components/BookCall";
import CreateTemplate from "../components/CreateTemplate";

import Session from "../components/Session";
import LoadingState from "../components/states/LoadingState";
import OnboardingWrapper from "../wrappers/OnboardingWrapper";
import Config from "../config";
import fetchFromApi from "../utils/fetchFromApi";

class Onboarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
    };
  }

  componentDidMount = async () => {
    document.title = `Onboarding ${Config.titleSuffix}`;

    const user = await Session.getUserData();
    if (!user.org) {
      navigate("/choose-organisation");
      return;
    }
    if (this.props.location?.search === "?skipToTemplate") {
      this.updateOnboarding(4);
      navigate("/onboarding");
    }
    const onboarding = this.getOnboardingStep(user);
    const organisations = await this.getOrganisations();
    this.setState({ pageLoading: false, onboarding, user, organisations });
  };

  getOnboardingStep = (user) => {
    let localStorageValue = localStorage.getItem("isOnboarding") || 0;
    let dbValue = user.org?.onboarding || 0;

    if (localStorageValue === "complete" || dbValue === "complete") {
      return "complete";
    }

    localStorageValue = parseInt(localStorageValue);
    dbValue = parseInt(dbValue);

    return Math.max(localStorageValue, dbValue);
  };

  async getOrganisations() {
    const path = "/admin/organisations/get";

    try {
      const response = await fetchFromApi(path, this.props.location.pathname);
      return response.organisations;
    } catch {
      return false;
    }
  }

  updateOnboarding = (value) => {
    localStorage.setItem("isOnboarding", value);
    this.setState({ onboarding: value });
  };

  // Skip the survey if the user is part of another organisation that has already completed the survey.
  skipSurvey = () => {
    const otherOrganisations = this.state?.organisations
      ? this.state.organisations.filter((o) => o.id !== this.state.user.org.id)
      : [];

    if (!otherOrganisations.length) {
      return false;
    }

    return otherOrganisations.some((o) => o.survey_customerType !== null);
  };

  onboardingSteps = (onboarding) => {
    switch (onboarding) {
      case 1:
        return (
          <Survey
            onboarding={true}
            location={this.props.location}
            updateOnboarding={this.updateOnboarding}
          />
        );
      case 2:
        return (
          <HubSpot
            onboarding={true}
            location={this.props.location}
            updateOnboarding={this.updateOnboarding}
          />
        );
      case 3:
        return (
          <BookCall
            onboarding={true}
            location={this.props.location}
            updateOnboarding={this.updateOnboarding}
          />
        );
      case 4:
        return (
          <CreateTemplate
            onboarding={true}
            location={this.props.location}
            updateOnboarding={this.updateOnboarding}
          />
        );
      default:
        return <Redirect noThrow={true} to="/" />;
    }
  };

  render() {
    const skipSurvey = this.skipSurvey();

    return (
      <>
        {this.state.pageLoading ? (
          <LoadingState />
        ) : (
          <OnboardingWrapper
            organisation={this.state?.user?.org}
            logOut={() => Session.logout("/")}
            onboarding={this.state.onboarding}
            user={this.state.user}
            updateOnboarding={this.updateOnboarding.bind(this)}
            skipSurvey={skipSurvey}
          >
            {this.onboardingSteps(this.state.onboarding)}
          </OnboardingWrapper>
        )}
      </>
    );
  }
}

export default Onboarding;
