import React from "react";
import zxcvbn from "zxcvbn";
import Session from "../Session";
import fetchFromApi from "../../utils/fetchFromApi";
import PasswordMeter from "../fields/PasswordMeter";
import Message from "../modals/Message";
import Modal from "../modals/Modal";

class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      username: "",
      password: "",
      fname: "",
      lname: "",
      terms: false,
      passwordScore: 0,
    };
  }

  async handleSubmit(e) {
    e.preventDefault();

    try {
      this.setState({ loading: true });

      if (!this.state.terms) {
        throw new Error("You must agree to the terms and conditions");
      }

      const path = this.props.invite
        ? "/auth/register-invite"
        : "/auth/register";

      const body = {
        password: this.state.password,
        fname: this.state.fname,
        lname: this.state.lname,
      };

      if (!this.props.invite) {
        body.email = this.state.username;

        var _hsq = (window._hsq = window._hsq || []);
        _hsq.push(["identify", { email: this.state.username }]);
        dataLayer.push({ event: "sign_up" });
      }

      const options = {
        method: "POST",
        body: JSON.stringify(body),
      };
      const response = await fetchFromApi(
        path,
        this.props.location.pathname,
        options,
      );

      Session.setUserData(response);

      console.log(response);
      this.setState({ loading: false });
      this.props.onSuccess(response);
    } catch (error) {
      this.setState({
        loading: false,
        error: true,
        errorDetails: {
          title: "Registration Failed",
          message:
            typeof error === "string"
              ? error
              : "We were unable to register your account. Please try again.",
        },
      });
    }
  }

  passwordChange(e) {
    var passwordValue = e.target.value;
    var passwordVerdict = zxcvbn(passwordValue).score + 1;
    this.setState({
      password: passwordValue,
      passwordScore: parseInt(passwordVerdict),
    });
  }

  render() {
    const disableSubmit =
      (!this.props.invite && !this.state.username) ||
      !this.state.fname ||
      !this.state.lname ||
      !this.state.password ||
      this.state.passwordScore < 4 ||
      !this.state.terms ||
      this.state.loading;
    return (
      <>
        <Message
          open={this.state.loading}
          type="loading"
          title="Creating user..."
        />
        <Modal
          open={this.state?.error}
          type="error"
          title={this.state.errorDetails?.title}
          message={this.state.errorDetails?.message}
          setClose={() => {
            this.setState({ error: false }),
              setTimeout(() => this.setState({ errorDetails: false }), 500);
          }}
        />

        <form
          id="linepilot-registration"
          className="max-w-lg"
          onSubmit={(e) => this.handleSubmit(e)}
        >
          {!this.props.invite && (
            <div className="mt-4">
              <label htmlFor="email" className="block m-0">
                Work Email
              </label>
              <div>
                <input
                  type="email"
                  value={this.state.username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                  required
                  className={
                    ((this.state?.username.includes("gmail.") &&
                      "!border-red-700 text-red focus:!ring-red-700 focus:!border-red-700") ||
                      "") + " block w-full"
                  }
                />
              </div>
            </div>
          )}
          <div className="flex">
            <div className="flex-1 mt-4 mr-4">
              <label htmlFor="fname" className="block m-0">
                First Name
              </label>
              <div>
                <input
                  type="text"
                  required
                  value={this.state.fname}
                  onChange={(e) => this.setState({ fname: e.target.value })}
                  className="block w-full"
                />
              </div>
            </div>

            <div className="flex-1 mt-4">
              <label htmlFor="lname" className="block m-0">
                Last Name
              </label>
              <div>
                <input
                  type="text"
                  required
                  value={this.state.lname}
                  onChange={(e) => this.setState({ lname: e.target.value })}
                  className="block w-full"
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="password" className="block m-0">
              Create a Password
            </label>
            <div>
              <input
                name="password"
                //on-input('checkStrength')
                value={this.state.password}
                type="password"
                onChange={(e) => this.passwordChange(e)}
                required
                className="block w-full"
              />
            </div>
            <div className="flex mt-1 -mx-1">
              <PasswordMeter passwordScore={this.state.passwordScore} />
            </div>
          </div>
          <label htmlFor="terms" className="flex items-center mt-4">
            <input
              type="checkbox"
              value={true}
              defaultChecked={this.state.terms}
              onChange={() => this.setState({ terms: !this.state.terms })}
              className="w-4 h-4 border-gray-300 rounded focus:ring-primary text-primary"
              required
            />
            <div className="ml-2">
              I agree to the{" "}
              <a
                href="https://www.linepilot.co/terms-of-service"
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                Terms of Service
              </a>
            </div>
          </label>

          <div className="mt-6 text-center">
            <input
              className="button-primary"
              type="submit"
              value="Register"
              disabled={disableSubmit}
            />
          </div>
        </form>
      </>
    );
  }
}

export default CreateUser;
