import React from "react";
import { Link, navigate } from "@reach/router";

import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";
import Config from "../config";
import BlankWrapper from "../wrappers/BlankWrapper";
import fetchFromApi from "../utils/fetchFromApi";

class PasswordRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      errorDetails: false,
      username: "",
    };
  }
  componentDidMount = async () => {
    document.title = "Reset Password" + Config.titleSuffix;
  };
  async handleSubmit(e) {
    e.preventDefault();
    try {
      this.setState({ loading: true });

      const path = "/auth/reset";
      const options = {
        method: "POST",
        body: JSON.stringify({ username: this.state.username }),
      };

      const response = await fetchFromApi(
        path,
        this.props.location.pathname,
        options,
      );

      if (response.success) {
        this.setState({ success: true, loading: false });
      }
    } catch (error) {
      this.setState({
        loading: false,
        error: true,
      });
      console.error(error);
    }
  }
  render() {
    return (
      <BlankWrapper>
        <div className="flex flex-row items-center justify-center max-w-screen-xl min-h-screen mx-auto">
          <div className="flex flex-col w-full max-w-sm">
            <h2 className="mt-6 text-center">Reset Password</h2>

            <Message
              open={this.state.loading}
              type="loading"
              title="Checking details..."
            />
            <Modal
              open={this.state?.error}
              type="error"
              title="Reset Failed"
              message="Couldn't reset your password - please check that you're using the correct email address"
              setClose={() => {
                this.setState({ error: false }),
                  setTimeout(() => this.setState({ errorDetails: false }), 500);
              }}
            />
            <Modal
              open={this.state?.success}
              type="success"
              title="Check your email"
              message="A link has been emailed to you to reset your password"
              buttonText="Back to Login"
              setClose={() => navigate("/login")}
            />
            <form
              className="max-w-lg min-w-fill "
              onSubmit={(e) => this.handleSubmit(e)}
            >
              <div className="mt-4">
                <label htmlFor="email">Email</label>
                <div>
                  <input
                    type="email"
                    value={this.state.username}
                    onChange={(e) =>
                      this.setState({ username: e.target.value })
                    }
                    required
                    className="w-full"
                  />
                </div>
              </div>

              <div className="text-center">
                <input
                  className="mt-4 button-primary"
                  type="submit"
                  value="Request New Password"
                  disabled={this.state.loading}
                />
              </div>

              <p className="flex items-center justify-center pb-5 mt-5 text-sm">
                <Link to="/login">Back to Login</Link>
              </p>
            </form>
          </div>
        </div>
      </BlankWrapper>
    );
  }
}

export default PasswordRequest;
