import React from "react";
import { Link, navigate } from "@reach/router";
import { motion } from "framer-motion";
import { DateTime } from "luxon";

import Config from "../config";
import Session from "../components/Session";
import { loadStripe } from "@stripe/stripe-js";
import LoadingState from "../components/states/LoadingState";
import QuestionBlock from "../components/blocks/QuestionBlock";
import TrialBlock from "../components/blocks/TrialBlock";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import classNames from "./../utils/classnames";
import fetchFromApi from "../utils/fetchFromApi";

class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      loading: false,
      error: false,
      errorDetails: false,
      success: false,
      deals: [],
      selected: [],
      portalUrl: null,
      runResetDate: null,
      monthly: false,
    };
  }

  componentDidMount = async () => {
    document.title = `Upgrade ${Config.titleSuffix}`;

    if (
      this.props.location?.search &&
      this.props.location.search.includes("billingredirect")
    ) {
      await Session.refreshUserData();
    }

    const user = await Session.getUserData();
    if (!user?.org) {
      navigate("/choose-organisation");
      return;
    }

    let portalUrl = null;
    if (user?.org?.plan === "starter" || user?.org?.plan === "pro") {
      portalUrl = await this.getPortalUrl();
    }

    const account = await this.getAccountStatus();
    const periodStartDate = DateTime.fromISO(user.org.periodStartDate);
    const runResetDate = periodStartDate.plus({ months: 1 }).toFormat("d LLLL");

    this.setState({
      pageLoading: false,
      user,
      portalUrl,
      runResetDate,
      account,
    });
  };

  getAccountStatus = async () => {
    try {
      const path = "/admin/org/account";
      const json = await fetchFromApi(path, this.props.location.pathname);

      return {
        runCount: Number(json.runCount),
        runLimit: Number(json.runLimit),
        templateLimit: Number(json.templateLimit),
        features: json.features,
      };
    } catch (error) {
      console.log("error", error);
      this.setState({ pageLoading: false });
    }
  };

  getPortalUrl = async () => {
    const path = "/billing/portal";
    const options = { method: "POST" };
    const response = await fetchFromApi(
      path,
      this.props.location.pathname,
      options,
    );
    return response.url;
  };

  handleSubmit = async (priceId) => {
    try {
      this.setState({ loading: true, pageLoading: true });
      const stripe = await loadStripe(Config.stripe.publicKey);

      const path = "/billing/session/" + priceId;
      const response = await fetchFromApi(path, this.props.location.pathname);

      stripe.redirectToCheckout({
        sessionId: response.sessionId,
      });
    } catch (error) {
      console.log("error", error);
      this.setState({ pageLoading: false });
    }
  };

  render() {
    const faqBlock = React.createRef();
    const runDefinition = React.createRef();

    const openRunDefinition = () => {
      runDefinition.current.toggleOpen();
      faqBlock.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
      <>
        {this.state.pageLoading ? (
          <LoadingState />
        ) : (
          <>
            <motion.div
              key="1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="flex flex-row h-screen max-w-screen-xl gap-10 mx-10 lg:mx-auto">
                <div className="flex-row flex-1 w-full">
                  <header className="flex flex-row pb-10 mb-5 border-b mt-36 border-tone-500">
                    <div className="flex flex-col items-baseline flex-grow gap-5">
                      <h1 className="">
                        {this.state?.user.org.plan === "partner"
                          ? "Plans & Pricing"
                          : !this.state?.portalUrl
                            ? "Upgrade"
                            : "Billing"}
                      </h1>
                    </div>
                  </header>

                  <div className="flex gap-5">
                    {this.state?.user.org.plan === "trial" && (
                      <TrialBlock
                        trialExpiry={this.state.user.org.trialExpiry}
                        runCount={this.state.account.runCount}
                        runLimit={this.state.account.runLimit}
                        showButton={false}
                      />
                    )}

                    {this.state?.account.runCount >= 0 &&
                      this.state?.user.org.plan !== "trial" && (
                        <div className="flex flex-col w-1/4 gap-2 card">
                          <h3>Number of Runs</h3>
                          <p className="text-4xl">
                            {this.state.account.runCount}
                            <span className="ml-1 text-xl">
                              /{" "}
                              {this.state.account.runLimit === Infinity ? (
                                <span>&#8734;</span>
                              ) : (
                                this.state.account.runLimit
                              )}
                            </span>
                          </p>
                          <p className="text-sm text-tone-700">
                            Your count resets on {this.state.runResetDate}
                          </p>
                        </div>
                      )}

                    <div className="relative flex flex-col w-1/4 gap-2 card">
                      <h3>Your Plan</h3>
                      <p className="text-4xl capitalize">
                        {this.state?.user.org.plan}
                      </p>
                      {this.state?.user.org.plan === "starter" && (
                        <a
                          href={this.state?.portalUrl}
                          className="flex items-center gap-1 transition-colors text-amber-500 hover:text-amber-600 group"
                        >
                          <SparklesIcon className="w-6 h-6 p-1 mr-2 text-white transition-colors rounded-full bg-amber-500 group-hover:bg-amber-600" />
                          <span className="text-sm">Upgrade here</span>
                        </a>
                      )}
                      {this.state?.user.org.plan === "pro" && (
                        <a
                          href="https://www.linepilot.co/support-resources"
                          className="flex items-center gap-1 transition-colors text-amber-500 hover:text-amber-600 group"
                        >
                          <SparklesIcon className="w-6 h-6 p-1 mr-2 text-white transition-colors rounded-full bg-amber-500 group-hover:bg-amber-600" />
                          <span className="text-sm">
                            Contact us for upgrades
                          </span>
                        </a>
                      )}
                    </div>
                    {this.state?.portalUrl && (
                      <div className="flex flex-col w-1/4 gap-2 card">
                        <h3>Manage Subscription</h3>
                        <p className="text-sm text-tone-700">
                          Access the customer portal to change payment methods,
                          upgrade or cancel subscriptions, or access your
                          invoices.
                        </p>
                        <a
                          href={this.state.portalUrl}
                          className="flex items-center gap-2 text-sm"
                        >
                          Go to Customer Portal
                          <ArrowRightIcon className="w-4 h-4" />
                        </a>
                      </div>
                    )}
                    <div className="flex flex-col w-1/4 gap-2 card">
                      <h3>Billing Questions</h3>
                      <p className="text-sm text-tone-700">
                        For Frequently Asked Questions and general information
                        about billing, please head to our support site.
                      </p>
                      <a
                        href="https://www.linepilot.co/articles/billing-questions"
                        className="flex items-center gap-2 text-sm"
                      >
                        Go to Support Site{" "}
                        <ArrowRightIcon className="w-4 h-4" />
                      </a>
                    </div>
                  </div>
                  {!this.state?.portalUrl && (
                    <div className="min-h-screen py-5 mt-5 text-center border-t border-tone-500">
                      <h2 className="mb-5">Available Plans</h2>
                      {this.state?.user.org.plan === "partner" && (
                        <p className="mb-5 text-sm">
                          Note to Partners: please do not sign up for a plan
                          here -{" "}
                          <Link
                            to="/logout"
                            onClick={() =>
                              Session.logout(this.props.location.pathname)
                            }
                          >
                            log out
                          </Link>{" "}
                          and create a new account for your client first
                        </p>
                      )}

                      <div
                        className="flex flex-row items-center flex-1 gap-5 mx-auto text-left justify-items-end"
                        id="pricing"
                      >
                        <div className="flex flex-col flex-1 gap-5 p-8 card">
                          <div>
                            <h3>Free</h3>
                            <p className="text-sm">
                              Light usage? Use LinePilot absolutely free
                            </p>
                          </div>
                          <div className="flex flex-row gap-5">
                            <div className="flex-1">
                              <span className="text-5xl font-semibold">$0</span>
                            </div>
                          </div>
                          <div className="mb-5 border-t border-t-tone-500"></div>
                          <ul className="flex flex-col gap-2 text-sm bullets-tick">
                            <li className="!text-tone-900 !pl-8">
                              20 runs per month
                            </li>
                            <li className="!text-tone-900 !pl-8">
                              1 template only
                            </li>
                          </ul>
                        </div>

                        <div className="flex flex-col flex-1 gap-8 p-8 card">
                          <div>
                            <h3>Starter</h3>
                            <p className="text-sm">
                              Unlock higher usage and multiple templates
                            </p>
                          </div>
                          <div className="flex flex-row items-center justify-end gap-5">
                            <div className="flex-1">
                              <span className="text-5xl font-semibold">
                                ${this.state.monthly ? "49" : "39"}
                              </span>
                              <span className="text-sm">/ month</span>
                            </div>
                            <div className="flex-shrink text-xs text-right">
                              <div>
                                Billed ${this.state.monthly ? "49" : "468"} USD{" "}
                                {this.state.monthly ? "monthly" : "annually"}
                              </div>
                              <div>
                                <button
                                  onClick={() =>
                                    this.setState({
                                      monthly: !this.state.monthly,
                                    })
                                  }
                                  className="text-primary"
                                >
                                  Show{" "}
                                  {this.state.monthly ? "annual" : "monthly"}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="mb-5 border-t border-t-tone-500"></div>
                          <ul className="flex flex-col gap-2 text-sm bullets-tick">
                            <li className="!text-tone-900 !pl-8">
                              Single price covers your entire HubSpot portal
                            </li>
                            <li className="!text-tone-900 !pl-8 flex items-center gap-2">
                              More runs: up to 300{" "}
                              <button
                                className="text-xs text-primary"
                                onClick={openRunDefinition}
                              >
                                What counts as a run?
                              </button>
                            </li>
                            <li className="!text-tone-900 !pl-8">
                              More templates: up to 3
                            </li>
                            <li className="!text-tone-900 !pl-8">
                              Premium Support
                            </li>
                          </ul>
                          <div className="flex flex-col justify-center gap-2">
                            <button
                              onClick={() =>
                                this.handleSubmit(
                                  this.state.monthly
                                    ? Config.pricing.starter.monthly
                                    : Config.pricing.starter.annual,
                                )
                              }
                              className={classNames(
                                "flex justify-center button-primary",
                                this.state?.user.org.plan === "partner" &&
                                  "group tooltip-container",
                              )}
                              disabled={
                                this.state.loading ||
                                this.state?.user.org.plan === "partner"
                              }
                            >
                              Buy Now
                              {this.state?.user.org.plan === "partner" && (
                                <span className="tooltip">
                                  Partners cannot sign up for plans. Please log
                                  out and create a new account for your client
                                  first.
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-col flex-1 gap-8 p-8 card">
                          <div>
                            <h3>Pro</h3>
                            <p className="text-sm">
                              Our most advanced features for power users
                            </p>
                          </div>
                          <div className="flex flex-row items-center justify-end gap-5">
                            <div className="flex-1">
                              <span className="text-5xl font-semibold">
                                ${this.state.monthly ? "76" : "66"}
                              </span>
                              <span className="text-sm">/ month</span>
                            </div>
                            <div className="flex-shrink text-xs text-right">
                              <div>
                                Billed ${this.state.monthly ? "76" : "792"} USD{" "}
                                {this.state.monthly ? "monthly" : "annually"}
                              </div>
                              <div>
                                <button
                                  className="text-primary"
                                  onClick={() =>
                                    this.setState({
                                      monthly: !this.state.monthly,
                                    })
                                  }
                                >
                                  Show{" "}
                                  {this.state.monthly ? "annual" : "monthly"}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="mb-5 border-t border-t-tone-500"></div>
                          <ul className="flex flex-col gap-2 text-sm bullets-tick">
                            <li className="!text-tone-900 !pl-8">
                              Single price covers your entire HubSpot portal
                            </li>
                            <li className="!text-tone-900 !pl-8 flex items-center gap-2">
                              Even more runs: up to 2000{" "}
                              <button
                                className="text-xs text-primary"
                                onClick={openRunDefinition}
                              >
                                What counts as a run?
                              </button>
                            </li>
                            <li className="!text-tone-900 !pl-8">
                              Even more templates: up to 300
                            </li>
                            <li className="!text-tone-900 !pl-8">
                              Run LinePilot from Workflows
                            </li>
                            <li className="!text-tone-900 !pl-8">
                              Populate custom line item properties
                            </li>
                            <li className="!text-tone-900 !pl-8">
                              Priority Support
                            </li>
                          </ul>
                          <div className="flex flex-col justify-center gap-2">
                            <button
                              onClick={() =>
                                this.handleSubmit(
                                  this.state.monthly
                                    ? Config.pricing.pro.monthly
                                    : Config.pricing.pro.annual,
                                )
                              }
                              className={classNames(
                                "flex justify-center button-primary",
                                this.state?.user.org.plan === "partner" &&
                                  "group tooltip-container",
                              )}
                              disabled={
                                this.state.loading ||
                                this.state.user.org.plan === "partner"
                              }
                            >
                              Buy Now
                              {this.state?.user.org.plan === "partner" && (
                                <span className="tooltip">
                                  Partners cannot sign up for plans. Please log
                                  out and create a new account for your client
                                  first.
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                      <h2 className="my-3">Your billing questions answered</h2>
                      <div
                        ref={faqBlock}
                        className="flex flex-col max-w-3xl mx-auto mb-10 text-left divide-y divide-gray-200"
                      >
                        <QuestionBlock
                          title="What counts as a run?"
                          ref={runDefinition}
                        >
                          Each time LinePilot meets your trigger criteria and
                          calculates the line items on your deal, this counts as
                          a single run. This applies even when your line items
                          are updated or if they are created. If there&apos;s an
                          error or the filters don&apos;t match, the run
                          won&apos;t be counted.
                        </QuestionBlock>
                        <QuestionBlock title="What happens if I go over the limit?">
                          LinePilot won&apos;t update your line items -
                          don&apos;t worry, you&apos;ll get an email alert when
                          this happens. If you&apos;re using workflows,
                          you&apos;ll also be able to see this in the HubSpot
                          workflows error message.
                        </QuestionBlock>

                        <QuestionBlock title="What payment methods do you accept?">
                          We accept all major credit cards. Regrettably we
                          cannot take payment in any other currency or via bank
                          transfer.
                        </QuestionBlock>
                        <QuestionBlock title="I'm a HubSpotter or Solutions Partner - can I get a demo account?">
                          Of course! We have free demo accounts for
                          non-production use - just reach out to
                          harry@linepilot.co
                        </QuestionBlock>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          </>
        )}
      </>
    );
  }
}

export default Billing;
