import React from "react";
import AsyncSelect from "react-select/async";
import Config from "../../config";
import fetchFromApi from "../../utils/fetchFromApi";

// TO DO - this is now defunct we can just use a normal search now on prodoctsList
class ProductSearch extends React.Component {
  constructor(props) {
    super(props);
  }

  timeout = null;
  doSearch = (query) => {
    return new Promise((resolve, reject) => {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(async () => {
        if (query.length < 3) {
          resolve([]);
          return;
        }

        query = query.replace(/ /g, "%20");
        const path = `/products/search?query=${query}`;
        try {
          const response = await fetchFromApi(
            path,
            this.props.location.pathname,
          );
          resolve(response.products);
        } catch (error) {
          reject(error);
        }
      }, 250); // Throttle calls
    });
  };

  loadOptions = (query) => {
    return this.doSearch(query);
  };

  render() {
    return (
      <AsyncSelect
        classNames={{
          control: () => "text-sm",
          loadingMessage: () => "text-sm",
          noOptionsMessage: () => "text-sm",
        }}
        loadingMessage={(e) =>
          e.inputValue.length < 3 ? "Type at least 3 characters" : "Searching"
        }
        noOptionsMessage={(e) =>
          e.inputValue.length < 3
            ? "Type at least 3 characters"
            : "Nothing found - check your HubSpot product library"
        }
        placeholder={"Find a product to add..."}
        className="min-w-[300px] w-fit"
        cacheOptions={true}
        getOptionLabel={(option) => {
          return (
            <>
              <span className="mr-2 text-xs text-toneDark">
                {option.properties?.hs_sku}
              </span>
              <span className="text-sm">{option.properties.name}</span>
            </>
          );
        }}
        isClearable
        openMenuOnFocus={true}
        loadOptions={this.loadOptions}
        theme={Config.selectTheme}
        value={this.props.value}
        onChange={(o) => this.props.onChange(o)}
      />
    );
  }
}

export default ProductSearch;
