import React, { useState, useEffect, useRef } from "react";
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
} from "@tanstack/react-table";

import classNames from "../../utils/classnames";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/solid";

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("datetime", {
    header: "Date / Time",
  }),
  columnHelper.accessor("template.name", {
    header: "Template Name",
    cell: (info) => {
      const template = info.cell.row.original.template;
      return template.link ? (
        <a href={template.link} rel="noopener noreferrer">
          {template.name}
        </a>
      ) : (
        template.name
      );
    },
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: (info) => {
      return info.cell.row.original.status === "success" ? (
        <span className="px-2 py-1 text-xs font-medium text-green-700 rounded-md bg-green-50 ring-green-600/20 ring-1 ring-inset">
          Success
        </span>
      ) : (
        <span className="px-2 py-1 text-xs font-medium text-red-700 rounded-md bg-red-50 ring-red-600/20 ring-1 ring-inset">
          Error
        </span>
      );
    },
  }),
  columnHelper.accessor("deal.id", {
    header: "Deal ID",
    cell: (info) => {
      const deal = info.cell.row.original.deal;
      return deal.link ? (
        <a
          href={deal.link}
          className="flex items-center gap-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          {deal.id} <ArrowTopRightOnSquareIcon className="w-3 h-3" />
        </a>
      ) : (
        deal.id
      );
    },
  }),
  columnHelper.accessor("output", {
    header: "Output",
    wrap: true,
  }),
  columnHelper.accessor("source", {
    header: "Source",
  }),
];

export default function LogTable({ data, updatePagination, rowCount }) {
  const isMounted = useRef(false);

  const [pagination, setPagination] = useState({
    pageSize: 100,
    pageIndex: 0,
  });

  const table = useReactTable({
    columns,
    data: data.data,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange: setPagination,
    state: { pagination },
    rowCount,
  });

  useEffect(() => {
    const skip = pagination.pageSize * pagination.pageIndex;

    if (data.skip != skip) {
      table.setPageIndex(data.skip);
    }
  }, [data]);

  useEffect(() => {
    if (isMounted.current) {
      updatePagination({
        skip: pagination.pageSize * pagination.pageIndex,
      });
    } else {
      isMounted.current = true;
    }
  }, [pagination]);

  return (
    <div className="flow-root mt-8">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="divide-y divide-gray-200">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={classNames(
                        cell.column.columnDef?.wrap
                          ? "whitespace-pre-wrap"
                          : "whitespace-nowrap",
                        "py-4 pl-4 pr-3 text-sm",
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          <footer className="flex items-center justify-center gap-2 my-10">
            <button
              className="button-link"
              disabled={!table.getCanPreviousPage()}
              onClick={table.previousPage}
            >
              <ArrowLongLeftIcon className="w-4 h-4" />
              {"Previous"}
            </button>
            <span className="text-sm">{`${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()}`}</span>
            <button
              className="button-link"
              disabled={!table.getCanNextPage()}
              onClick={table.nextPage}
            >
              {"Next"}
              <ArrowLongRightIcon className="w-4 h-4" />
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
}
