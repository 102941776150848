import React from "react";
import { TrashIcon } from "@heroicons/react/24/solid";
import Select from "react-select";
import Config from "../config";
class ConditionSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyDefinition: false,
    };
  }
  componentDidMount() {
    const propertyList =
      this.props.data?.type === "line"
        ? this.props.lineItemPropertyDefinitions
        : this.props.dealPropertyDefinitions;

    const propertyDefinition = propertyList.find(
      (p) => p.name === this.props.data.property,
    );
    this.setState({ propertyDefinition });
  }

  async updateProperty(e) {
    const propertyList =
      this.props.data?.type === "line"
        ? this.props.lineItemPropertyDefinitions
        : this.props.dealPropertyDefinitions;
    const propertyDefinition = propertyList.find((p) => p.name === e?.value);
    this.setState({ propertyDefinition });
    this.props.updateFilter(
      this.props.data.id,
      e?.value,
      false,
      this.props.data.operator || "equals",
    );
  }

  async updateValue(e) {
    this.props.updateFilter(
      this.props.data.id,
      this.props.data.property,
      e.target.value,
      this.props.data.operator || "equals",
    );
  }

  render() {
    const { data } = this.props;
    const { propertyDefinition } = this.state;

    const dealPropertyDefinitions = this.props.dealPropertyDefinitions
      .map((p) => {
        if (p.referencedObjectType && p.name !== "hubspot_owner_id")
          return false;
        if (p.fieldType === "calculation") return false;
        if (p.fieldType === "calculation_read_time") return false;
        if (
          p.type === "enumeration" &&
          p.options.length === 0 &&
          p.name !== "dealstage" &&
          p.name !== "pipeline"
        )
          return false;

        if (p.name === "pipeline") {
          p.options = this.props.dealStages.map((s) => {
            return {
              label: s.label,
              value: s.id,
            };
          });
        }
        if (p.name === "dealstage") {
          let stages = [];
          for (let pipeline of this.props.dealStages) {
            let pipelineStages = pipeline.stages.map((s) => {
              return {
                label: `${pipeline.label} - ${s.label}`,
                value: s.id,
              };
            });
            stages = [...stages, ...pipelineStages];
          }
          p.options = stages;
        }
        return p;
      })
      .filter((p) => p !== false);

    const lineItemPropertyDefinitions =
      this.props?.lineItemPropertyDefinitions &&
      this.props.lineItemPropertyDefinitions
        .map((p) => {
          if (p.fieldType === "calculation") return false;
          if (p.fieldType === "calculation_read_time") return false;
          if (p.type === "enumeration" && p.options.length === 0) return false;
          return p;
        })
        .filter((p) => p !== false);

    const createSelectOperator = (options) => (
      <select
        className="text-xs font-bold bg-transparent border-none appearance-none select-special fill-primary focus:ring-0 text-primary"
        onChange={(e) =>
          this.props.updateFilter(
            data.id,
            data.property,
            data.value,
            e.target.value,
          )
        }
      >
        {options.map(([value, label]) => (
          <option key={label} value={value} selected={data.operator === value}>
            {label}
          </option>
        ))}
      </select>
    );

    const propertyList =
      data?.type === "line"
        ? lineItemPropertyDefinitions
        : dealPropertyDefinitions;

    return (
      <div className="mb-4">
        <div className="flex flex-row gap-2">
          {this.props.index > 0 && (
            <div className="text-xs text-tone-700">AND</div>
          )}
          <div className="text-xs">
            {data.type === "line"
              ? "When any line item on the deal matches..."
              : "When deal property matches..."}
          </div>
        </div>
        <div className="flex flex-row items-center gap-5 mt-2 ml-5">
          <div className="flex-1">
            <Select
              className="text-sm "
              options={propertyList.map((p) => {
                return {
                  label: p.label,
                  value: p.name,
                };
              })}
              isClearable="true"
              defaultValue={propertyList.find((p) => p.name === data.property)}
              theme={Config.selectTheme}
              onChange={(o) => this.updateProperty(o)}
            />
          </div>

          <div className="flex-shrink">
            {propertyDefinition &&
              (propertyDefinition.type === "string"
                ? createSelectOperator([
                    ["equals", "equals"],
                    ["contains", "contains"],
                    ["doesNotContain", "does not contain"],
                    ["notEquals", "does not equal"],
                    ["startsWith", "starts with"],
                    ["endsWith", "ends with"],
                  ])
                : propertyDefinition.type === "number"
                  ? createSelectOperator([
                      ["equals", "equals"],
                      ["greaterThan", "is greater than"],
                      ["lessThan", "is less than"],
                    ])
                  : propertyDefinition.type === "enumeration"
                    ? createSelectOperator([
                        ["equals", "equals"],
                        ["notEquals", "does not equal"],
                        ["in", "contains"],
                        ["notIn", "does not contain"],
                      ])
                    : propertyDefinition.fieldType === "date" &&
                      createSelectOperator([
                        ["equals", "equals"],
                        ["greaterThan", "is after"],
                        ["lessThan", "is before"],
                      ]))}
          </div>
          <div className="flex-1">
            {propertyDefinition && (
              <div className="flex-grow">
                {propertyDefinition.type === "string" && (
                  <input
                    type="text"
                    className="w-full"
                    name={propertyDefinition.name}
                    value={data.value || ""}
                    onChange={this.updateValue.bind(this)}
                  />
                )}

                {propertyDefinition.type === "number" && (
                  <input
                    type="number"
                    className="w-full"
                    name={propertyDefinition.name}
                    value={data.value || ""}
                    onChange={this.updateValue.bind(this)}
                  />
                )}

                {propertyDefinition.fieldType === "date" && (
                  <input
                    type="date"
                    className="w-full"
                    name={propertyDefinition.name}
                    value={data.value || ""}
                    onChange={this.updateValue.bind(this)}
                  />
                )}

                {(propertyDefinition.type === "enumeration" ||
                  propertyDefinition.type === "bool") && (
                  <select
                    className="w-full"
                    onChange={this.updateValue.bind(this)}
                  >
                    <option value="" disabled selected={!data?.value}>
                      Choose option
                    </option>
                    {propertyDefinition?.options?.map((o) => {
                      return (
                        <option
                          key={o.value}
                          value={o.value}
                          selected={data.value === o.value}
                        >
                          {o.label}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
            )}
          </div>
          <div className="flex items-center flex-shrink">
            <button
              className="button-icon"
              onClick={() => this.props.removeFilter(data.id)}
            >
              <TrashIcon className="w-3 h-3" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ConditionSelector;
