import React from "react";
import fetchFromApi from "../../utils/fetchFromApi";

class InviteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
      success: false,
      error: false,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    const path = "/admin/organisation/invite";
    const options = {
      method: "POST",
      body: JSON.stringify({
        email: this.state.email,
      }),
    };

    try {
      const response = await fetchFromApi(path, "/", options);
      if (response.success) {
        this.setState({ success: true, loading: false });
      }
    } catch (error) {
      if (error === "User already part of this organisation") {
        this.setState({ error, loading: false });
      } else {
        this.setState({ loading: false });
      }
    }
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  render() {
    return (
      <>
        <p className="text-left">
          Enter an email address to grant access to your dashboard.
        </p>
        <p className="mt-2 text-sm font-light text-left">
          Note: You only need to do this for admins, for example your internal
          HubSpot admin, finance manager, or HubSpot Solutions Partner.{" "}
          <a href="#">Find out more</a>
        </p>
        <form
          className="flex flex-col max-w-lg mx-auto"
          onSubmit={this.handleSubmit}
        >
          {this.state.success ? (
            <>
              <div className="mt-5 font-medium text-green-900">
                Done! An invite email has been sent
              </div>
              <button
                className="mx-auto mt-4 button-secondary"
                onClick={() =>
                  this.setState({ success: false, email: "", error: false })
                }
              >
                Send another invite
              </button>
            </>
          ) : (
            <>
              <div className="mt-4">
                <div>
                  <input
                    name="email"
                    value={this.state.email}
                    placeholder="Email address"
                    type="email"
                    onChange={this.handleEmailChange}
                    required
                    className="block w-full"
                  />
                </div>
              </div>

              {this.state.error && (
                <div className="text-red-800">{this.state.error}</div>
              )}

              <div className="mt-4 text-center">
                <input
                  className="button-primary"
                  type="submit"
                  value="Send Invite"
                  onClick={(e) => this.handleSubmit(e)}
                  disabled={this.state.loading}
                />
              </div>
            </>
          )}
        </form>
      </>
    );
  }
}

export default InviteForm;
