import React from "react";
import { navigate } from "@reach/router";
import Session from "../Session";
import CountrySelect from "../fields/CountrySelect";
import fetchFromApi from "../../utils/fetchFromApi";
import Message from "../modals/Message";
import Modal from "../modals/Modal";

class CreateOrganisation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      loading: false,
      company: "",
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const path = "/admin/organisation/create";
    const options = {
      method: "POST",
      body: JSON.stringify({
        company: this.state.company,
        country: this.state.country,
        onboarding: this.props?.skipSurvey ? 2 : 1,
      }),
    };

    try {
      const response = await fetchFromApi(
        path,
        this.props.location.pathname,
        options,
      );

      console.log("no errors, setting session");
      Session.setUserData(response);

      navigate("/onboarding");
    } catch (error) {
      this.setState({
        loading: false,
        error: true,
        errorDetails: {
          title: "Organisation Creation Failed",
          message:
            typeof error === "string"
              ? error
              : "We were unable to create your organisation. Please try again.",
        },
      });
    }
  };

  render() {
    const disableSubmit =
      !this.state.company || !this.state.country || this.state.loading;

    return (
      <>
        <Message
          open={this.state.loading}
          type="loading"
          title="Creating organisation..."
        />
        <Modal
          open={this.state?.error}
          type="error"
          title={this.state.errorDetails?.title}
          message={this.state.errorDetails?.message}
          setClose={() => {
            this.setState({ error: false }),
              setTimeout(() => this.setState({ errorDetails: false }), 500);
          }}
        />

        <div className="flex flex-col items-center">
          <form
            id="linepilot-starttrial"
            className="max-w-lg"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <div className="mt-4">
              <label htmlFor="company" className="block m-0">
                Company Name
              </label>
              <div>
                <input
                  type="text"
                  value={this.state.company}
                  onChange={(e) => this.setState({ company: e.target.value })}
                  required
                  className="block w-full"
                />
              </div>
            </div>

            <div className="mt-4">
              <label htmlFor="country" className="block m-0">
                Country
              </label>
              <CountrySelect
                onChange={(selectedOption) =>
                  this.setState({ country: selectedOption?.value })
                }
              />
            </div>
            <div className="mt-6 text-center">
              <input
                className="button-primary"
                type="submit"
                value="Start Trial"
                disabled={disableSubmit}
              />
            </div>
          </form>
        </div>
      </>
    );
  }
}

export default CreateOrganisation;
