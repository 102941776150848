import React from "react";
import { Puff } from "svg-loaders-react";

export default function LoadingState({ h1 = "Loading..." }) {
  return (
    <div className="flex items-center min-h-screen -mt-24 first-letter:justify-contents">
      <span className="flex flex-col items-center flex-auto">
        <span className="flex-auto m-3">
          <Puff
            strokeWidth="2"
            className="stroke-primary"
            strokeOpacity="1"
            aria-hidden="true"
          />
        </span>
        <span className="flex-auto text-base text-gray-800 ">{h1}</span>
      </span>
    </div>
  );
}
