import React from "react";
import { Link, navigate } from "@reach/router";
import Session from "../components/Session";
import fetchFromApi from "../utils/fetchFromApi";
import LoadingState from "../components/states/LoadingState";
import { XMarkIcon } from "@heroicons/react/24/solid";
import BlankWrapper from "../wrappers/BlankWrapper";
import Config from "../config";
import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";

class ChooseOrganisation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      error: false,
      success: false,
      loading: false,
      organisations: [],
    };
  }

  componentDidMount = async () => {
    document.title = `Choose Organisation ${Config.titleSuffix}`;
    await this.getStatus();
  };

  async getStatus() {
    const path = "/admin/organisations/get";

    try {
      const response = await fetchFromApi(path, this.props.location.pathname);

      if (!response.organisations.length) {
        navigate("/start-trial");
      }

      this.setState({
        organisations: response.organisations,
        pageLoading: false,
      });
    } catch (error) {
      this.setState({
        pageLoading: false,
        error: true,
        errorDetails: {
          title: "Could not get organisation",
          message:
            typeof error === "string"
              ? error
              : "We were unable to retrieve the organisation. Please try again.",
        },
      });
    }
  }

  selectOrganisation = async (orgId) => {
    this.setState({ loading: true });

    const path = "/admin/organisation/select";
    const options = {
      method: "POST",
      body: JSON.stringify({
        orgId,
      }),
    };

    try {
      const response = await fetchFromApi(
        path,
        this.props.location.pathname,
        options,
      );

      console.log("no errors, setting session");
      Session.setUserData(response);

      const navigateTo =
        response.user.org.onboarding !== "complete" ? "/onboarding" : "/";
      navigate(navigateTo);
    } catch (error) {
      this.setState({
        loading: false,
        error: true,
        errorDetails: {
          title: "Could not select organisation",
          message:
            typeof error === "string"
              ? error
              : "We were unable to select this organisation. Please try again.",
        },
      });
    }
  };

  render() {
    if (this.state.pageLoading) {
      return <LoadingState />;
    }

    return (
      <>
        <Message
          open={this.state.loading}
          type="loading"
          title="Selecting organisation..."
        />
        <Modal
          open={this.state?.error}
          type="error"
          title={this.state.errorDetails?.title}
          message={this.state.errorDetails?.message}
          setClose={() => {
            this.setState({ error: false }),
              setTimeout(() => this.setState({ errorDetails: false }), 500);
          }}
        />
        <BlankWrapper hideNav={true}>
          <div className="flex flex-row items-center justify-center max-w-screen-xl min-h-screen mx-auto">
            <div className="flex flex-col w-full max-w-sm">
              <nav className="absolute z-20 top-5 right-5">
                <Link to="/">
                  <XMarkIcon className="w-8 h-8" />
                </Link>
              </nav>

              <div className="flex flex-col border-none card">
                <h2>Choose Organisation</h2>

                <ul className="flex flex-col text-lg">
                  {this.state.organisations.map((organisation) => {
                    const initials = organisation?.name
                      ?.split(" ")
                      .map((word) => word[0].toUpperCase())
                      .join("")
                      .substring(0, 3);

                    return (
                      <li
                        key={organisation.id}
                        className="flex flex-row items-center w-full gap-5 px-5 py-5 font-normal text-left rounded-lg cursor-pointer hover:bg-tone-100"
                        onClick={() => this.selectOrganisation(organisation.id)}
                      >
                        <object
                          data={`https://logo.clearbit.com/${organisation?.domain}`}
                          className="w-12 h-12 rounded-full"
                          type="image/jpeg"
                        >
                          <div className="flex items-center justify-center w-full h-full font-bold text-white bg-tone-700">
                            {initials}
                          </div>
                        </object>

                        {organisation.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </BlankWrapper>
      </>
    );
  }
}

export default ChooseOrganisation;
