import React from "react";
import { Router, globalHistory } from "@reach/router";
import { AnimatePresence } from "framer-motion";

import Error from "./admin/Error";
import Login from "./admin/Login";
import PasswordRequest from "./admin/PasswordRequest";
import PasswordChange from "./admin/PasswordChange";

import Dashboard from "./wrappers/Dashboard";

import Onboarding from "./pages/Onboarding";
import Register from "./pages/Register";
import Billing from "./pages/Billing";
import Organisation from "./pages/Organisation";
import RegisterInvite from "./pages/RegisterInvite";
import SubscriptionConfirmed from "./pages/SubscriptionConfirmed";
import TemplateList from "./pages/TemplateList";
import Template from "./pages/Template";
import Logs from "./pages/Logs";
import Support from "./pages/Support";
import StartTrial from "./pages/StartTrial";
import AcceptInvite from "./pages/AcceptInvite";
import ChooseOrganisation from "./pages/ChooseOrganisation";

import HubSpot from "./components/HubSpot";

import PrivateRoute from "./PrivateRoute";
import ErrorBoundary from "./components/states/ErrorBoundary";
import ErrorState from "./components/states/ErrorState";

import "./assets/tailwind.css";

// Tracking Code HubSpot & GA
globalHistory.listen(function ({ location }) {
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push(["setPath", location.pathname]);
  _hsq.push(["trackPageView"]);
  // Reload HubSpot CTA embeds otherwise they don't show on page navigation
  window.hubspot_web_interactives_running = false;
  var script = document.createElement("script");
  script.src = "https://js.hubspot.com/web-interactives-embed.js";
  document.body.appendChild(script);
});

// getting HubSpot live chat availablity
window.hsConversationsOnReady = [
  async () => {
    await fetch("https://availability-check.weaveandblend.com/get")
      .then((response) => response.json())
      .then((data) => {
        if (data.isAvailable) {
          window.HubSpotConversations.widget.load();
        }
      })
      .catch((error) => {
        console.error("Error fetching HubSpot chat availability", error);
      });
  },
];

// Container Component
class App extends React.Component {
  componentDidMount = async () => {};

  render() {
    return (
      <ErrorBoundary>
        <AnimatePresence>
          <Router>
            <PrivateRoute path="/" exact component={Dashboard}>
              <TemplateList path="/" exact />
              <Logs path="/logs" exact />
              <Template path="/template/:templateId" />
              <HubSpot path="/hubspot" onboarding={false} />
              <Billing path="/billing" onboarding={false} />
              <Support path="/support" onboarding={false} />
              <Organisation path="/organisation" />
              <SubscriptionConfirmed path="/subscription-confirmed" />
              <PasswordChange path="/password" />
              <ErrorState default h1="Page not found" fullPage={false} />
            </PrivateRoute>
            <PrivateRoute path="/accept-invite" component={AcceptInvite} />
            <PrivateRoute
              path="/choose-organisation"
              component={ChooseOrganisation}
            />
            <PrivateRoute path="/onboarding" component={Onboarding} />
            <PrivateRoute path="/start-trial" component={StartTrial} />
            <Login exact path="/login/*" />
            <Error exact path="/error" />
            <PasswordRequest path="/login/password-reset" addWrapper={true} />
            <Register path="/register" />
            <RegisterInvite path="/register-invite" />
            <ErrorState default h1="Page Not Found" />
          </Router>
        </AnimatePresence>
      </ErrorBoundary>
    );
  }
}

export default App;
