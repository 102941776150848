import React from "react";
import { TrashIcon } from "@heroicons/react/24/solid";
import Select from "react-select";
import classNames from "./../utils/classnames";
import Config from "../config";

class LineItemPropertyInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType:
        this.props?.data.type || this.props.propertyDefinition?.type,
    };
  }

  async handleChange(e) {
    this.props.updateProperty(
      this.props.propertyDefinition.name,
      e?.target?.value || e?.value,
      this.state.selectedType,
    );
  }

  render() {
    const dateTokenOptions = [
      { label: "Today", value: "{{date.now}}" },
      { label: "Start of current month", value: "{{date.startOfMonth}}" },
      { label: "End of current month", value: "{{date.endOfMonth}}" },
    ];
    const { dealPropertyDefinitions, propertyDefinition, data } = this.props;
    return (
      <div className="flex flex-row items-center gap-5 group/lineitemproperty">
        <div className="flex flex-col w-1/4">
          <label className="flex flex-row items-center text-sm">
            {propertyDefinition?.label}{" "}
            {this.props.required && (
              <span className="ml-2 text-tone-700">*</span>
            )}
          </label>
          {this.props.isOverwritten && (
            <span className="w-full text-xs font-normal text-tone-700">
              Replacing product data
            </span>
          )}

          {/*<dd className="text-xs text-toneDark">{propertyDefinition?.description}</dd>*/}
        </div>
        <select
          className="text-xs font-bold border-none appearance-none select-special fill-primary focus:ring-0 text-primary"
          onChange={(e) => this.setState({ selectedType: e.target.value })}
        >
          <option
            value="property"
            selected={this.state.selectedType === "property"}
          >
            Use deal property
          </option>
          {propertyDefinition.type === "enumeration" && (
            <option
              value="option"
              selected={
                this.state.selectedType === "enumeration" ||
                this.state.selectedType === "option"
              }
            >
              Choose option
            </option>
          )}
          {propertyDefinition.type === "string" && (
            <option
              value="text"
              selected={this.state.selectedType === "string"}
            >
              Enter text
            </option>
          )}
          {propertyDefinition.type === "number" && (
            <option
              value="number"
              selected={this.state.selectedType === "number"}
            >
              Enter number
            </option>
          )}
          {propertyDefinition.fieldType === "date" && (
            <option value="date" selected={this.state.selectedType === "date"}>
              Enter fixed date
            </option>
          )}
          {propertyDefinition.fieldType === "date" && (
            <option
              value="token"
              selected={this.state.selectedType === "token"}
            >
              Use dynamic date
            </option>
          )}
        </select>

        {this.state.selectedType === "property" && (
          <div className="flex flex-col flex-grow">
            <Select
              className={classNames(
                "w-full text-sm",
                propertyDefinition.type === "enumeration" && "mt-9",
              )}
              options={dealPropertyDefinitions
                .map((p) => {
                  return {
                    label: p.label,
                    value: "{{" + p.name + "}}",
                  };
                })
                .sort((a, b) => a.label.localeCompare(b.label))}
              isClearable="true"
              defaultValue={dealPropertyDefinitions.find(
                (p) => "{{" + p.name + "}}" === data.value,
              )}
              // formatOptionLabel={(o) => (
              //   <div className="flex flex-col items-left">
              //     <div>{o.label}</div>
              //     <div className="text-xs text-tone-700 ">{o.value.replace(/[{}]/g, '')}</div>
              //   </div>
              // )}
              theme={Config.selectTheme}
              onChange={this.handleChange.bind(this)}
            />
            {propertyDefinition.type === "enumeration" && (
              <span className="text-xs leading-snug max-w-[300px] mt-2">
                Note - deal property values must exactly match product property
                values, or you could get errors
              </span>
            )}
          </div>
        )}

        {this.state.selectedType === "token" && (
          <div className="flex flex-col flex-grow">
            <Select
              className={classNames(
                "w-full text-sm",
                propertyDefinition.type === "enumeration" && "mt-9",
              )}
              value={dateTokenOptions.find((o) => o.value === data.value)}
              options={dateTokenOptions}
              theme={Config.selectTheme}
              onChange={this.handleChange.bind(this)}
            />

            <span className="text-xs leading-snug max-w-[300px] mt-2">
              Note - All dates are relative to the date the template is run
            </span>
          </div>
        )}

        {this.state.selectedType === "property" ||
          this.state.selectedType === "token" || (
            <div className="flex-grow">
              {propertyDefinition.type === "string" && (
                <input
                  type="text"
                  className="w-full"
                  name={propertyDefinition.name}
                  value={data.value || ""}
                  onChange={this.handleChange.bind(this)}
                />
              )}

              {propertyDefinition.type === "number" && (
                <>
                  <input
                    type="text"
                    className="w-full"
                    pattern="\d*\.?\d*"
                    name={propertyDefinition.name}
                    value={
                      data.value !== undefined && data.value !== false
                        ? data.value
                        : ""
                    }
                    onChange={(e) => {
                      const regex = /^\d*\.?\d*$/; // Allows only digits
                      if (regex.test(e.target.value)) {
                        this.setState({ value: e.target.value }); // Ensure valid values
                        this.handleChange(e); // Call your change handler
                      }
                    }}
                  />
                </>
              )}

              {propertyDefinition.fieldType === "date" && (
                <input
                  type="date"
                  className="w-full"
                  name={propertyDefinition.name}
                  value={data.value || ""}
                  onChange={this.handleChange.bind(this)}
                />
              )}

              {propertyDefinition.type === "enumeration" && (
                <select
                  className="w-full"
                  onChange={this.handleChange.bind(this)}
                >
                  <option value="" disabled selected={!data?.value}>
                    Choose option
                  </option>
                  {propertyDefinition?.options?.map((o) => {
                    return (
                      <option
                        key={o.value}
                        value={o.value}
                        selected={data.value === o.value}
                      >
                        {o.label}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
          )}

        {this.props.required ? (
          <span className="text-xs text-tone-700">* Required</span>
        ) : (
          <button
            className="button-icon"
            onClick={() => this.props.removeProperty(propertyDefinition.name)}
          >
            <TrashIcon className="w-3 h-3" />
          </button>
        )}
      </div>
    );
  }
}

export default LineItemPropertyInput;
