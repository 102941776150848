import React from "react";
import { motion } from "framer-motion";
import { Link, navigate } from "@reach/router";

import LoadingState from "../components/states/LoadingState";
import fetchFromApi from "../utils/fetchFromApi";
import Session from "../components/Session";
import InviteBlock from "../components/blocks/InviteBlock";
import Config from "../config";
import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

class Organisation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLoading: true,
      error: false,
      success: false,
      loading: false,
      users: [],
    };
  }
  componentDidMount = async () => {
    document.title = `My Organisation ${Config.titleSuffix}`;

    const user = await Session.getUserData();
    if (!user?.org) {
      navigate("/choose-organisation");
      return;
    }

    await this.getStatus();
    this.setState({ pageLoading: false, user });
  };
  async getStatus() {
    const path = "/admin/organisation/get";

    try {
      const response = await fetchFromApi(path, this.props.location.pathname);
      this.setState({ users: response.users });
    } catch (error) {
      this.setState({
        pageLoading: false,
        error: true,
        errorDetails: {
          title: "Could not get organisation",
          message:
            typeof error === "string"
              ? error
              : "We were unable to retrieve the organisation. Please try again.",
        },
      });
    }
  }

  async revokeAccess(userId) {
    this.setState({ loading: true });

    const path = "/admin/organisation/revoke";
    const options = {
      method: "POST",
      body: JSON.stringify({ userId }),
    };

    try {
      const response = await fetchFromApi(
        path,
        this.props.location.pathname,
        options,
      );

      if (response.success) {
        await this.getStatus();
        this.setState({ success: true, loading: false });
      }
    } catch (error) {
      this.setState({
        loading: false,
        error: true,
        errorDetails: {
          title: "Could not revoke user",
          message:
            typeof error === "string"
              ? error
              : "We were unable to revoke this user. Please try again.",
        },
      });
    }
  }

  render() {
    if (this.state.pageLoading) {
      return <LoadingState />;
    }

    const users = this.state.users.filter((u) => u.id !== this.state.user.id);

    return (
      <>
        <Message
          open={this.state.loading}
          type="loading"
          title="Revoking user..."
        />
        <Modal
          open={this.state?.error}
          type="error"
          title={this.state.errorDetails?.title}
          message={this.state.errorDetails?.message}
          setClose={() => {
            this.setState({ error: false }),
              setTimeout(() => this.setState({ errorDetails: false }), 500);
          }}
        />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-row h-screen max-w-screen-xl gap-10 mx-10 lg:mx-auto">
            <div className="flex-row flex-1 w-full ">
              <header className="relative flex flex-row pb-10 mb-5 border-b mt-36 border-tone-500">
                <div className="flex flex-col items-baseline flex-grow gap-5">
                  <h1 className="">My Organisation</h1>
                </div>
              </header>
              <div className="relative my-10 ">
                <div className="flex flex-col ">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 text-xs font-light tracking-wider text-left uppercase">
                          Name
                        </th>
                        <th className="px-6 py-3 text-xs font-light tracking-wider text-left uppercase">
                          Email Address
                        </th>
                        <th className="px-6 py-3 text-xs font-light tracking-wider text-left uppercase">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="">
                        <td className="px-6 py-4 text-sm whitespace-nowrap">
                          {`${this.state.user.fname} ${this.state.user.lname}`}{" "}
                          (me)
                        </td>
                        <td className="px-6 py-4 text-sm whitespace-nowrap">
                          {this.state.user.username}
                        </td>
                        <td className="px-6 py-4 text-sm whitespace-nowrap">
                          <Link className="button-text" to="/password">
                            Change Password
                          </Link>
                        </td>
                      </tr>
                      {users.map((user) => (
                        <tr key={user.id} className="">
                          <td className="px-6 py-4 text-sm whitespace-nowrap">{`${user.fname} ${user.lname}`}</td>
                          <td className="px-6 py-4 text-sm whitespace-nowrap">
                            {user.username}
                          </td>
                          <td className="px-6 py-4 text-sm whitespace-nowrap">
                            <button
                              onClick={() => this.revokeAccess(user.id)}
                              className="button-text"
                            >
                              Revoke Access
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <InviteBlock title="Add Users" />
                </div>
                <div className="absolute top-0 flex-col hidden gap-2 ml-16 lg:flex left-full h-min w-96">
                  <h3 className="flex items-center gap-2 mt-0">
                    <QuestionMarkCircleIcon className="w-7 h-7 -ml-3.5 " />
                    Users
                  </h3>
                  <p className="mt-5 text-sm">
                    These are the current admin users for your{" "}
                    {this.state.user.org.name} Line Pilot account. Remember, you
                    only need to add a user if they need to access the admin
                    dashboard.
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden lg:flex lg:basis-96"></div>
          </div>
        </motion.div>
      </>
    );
  }
}

export default Organisation;
