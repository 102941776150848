import React from "react";
import Select, { components } from "react-select";
import Config from "../../config";
import countryList from "react-select-country-list";
import "../../assets/flag-css/flag-icons.min.css";

export default function CountrySelect({ onChange }) {
  const countryControl = ({ children, ...props }) => {
    let selectedOption = props.getValue();
    let classes =
      "fis rounded-full h-5 w-5 bg-center ml-2 mr-1 fi-" +
      selectedOption[0]?.value.toLowerCase();
    return (
      <components.Control {...props}>
        <div className={classes}></div>
        {children}
      </components.Control>
    );
  };

  const countryOption = (props) => {
    let selectedOption = props.data.value
      ? props.data.value.toLowerCase()
      : null;
    let classes =
      "fis rounded-full h-5 w-5 bg-center mr-2 fi-" + selectedOption;
    return (
      <components.Option {...props}>
        <div
          className={
            (props.isSelected ? "text-white " : "") + "flex items-center"
          }
        >
          <div className={classes}></div>
          {props.children}
        </div>
      </components.Option>
    );
  };

  return (
    <Select
      className="flex-1"
      name="country"
      id="country"
      options={countryList().getData()}
      isClearable="true"
      components={{ Option: countryOption, Control: countryControl }}
      theme={Config.selectTheme}
      // onChange={(selectedOption) => this.setState({ country: selectedOption?.value })}
      onChange={onChange}
    />
  );
}
