import React from "react";
import Select from "react-select";
import Config from "../../config";

export default function SelectMenu({
  label,
  options,
  onChange,
  id,
  name,
  defaultValue,
}) {
  return (
    <div>
      <label htmlFor={id} className="block font-medium text-sm/6">
        {label}
      </label>
      <div className="">
        <Select
          className="text-sm md:max-w-sm"
          id={id}
          isClearable={true}
          name={name}
          options={options}
          onChange={onChange}
          defaultValue={defaultValue}
          theme={Config.selectTheme}
        />
      </div>
    </div>
  );
}
