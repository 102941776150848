import React from "react";
import zxcvbn from "zxcvbn";

import Config from "../config";
import OnboardingWrapper from "../wrappers/OnboardingWrapper";
import OptionField from "../components/fields/OptionField";
import CreateUser from "../components/forms/CreateUser";
import CreateOrganisation from "../components/forms/CreateOrganisation";

import { SparklesIcon } from "@heroicons/react/24/outline";

import { ReactComponent as Rocket } from "../assets/images/icons8-rocket.svg";
import { ReactComponent as Enter } from "../assets/images/icons8-enter.svg";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
    };
  }
  componentDidMount = async () => {
    document.title = `Register ${Config.titleSuffix}`;
    this.updateOnboarding(0);
  };

  passwordChange(e) {
    var passwordValue = e.target.value;
    var passwordVerdict = zxcvbn(passwordValue).score + 1;
    this.setState({
      password: passwordValue,
      passwordScore: parseInt(passwordVerdict),
    });
  }

  updateOnboarding = (value) => {
    localStorage.setItem("isOnboarding", value);
    this.setState({ onboarding: value });
  };

  render() {
    return (
      <OnboardingWrapper onboarding={0}>
        {this.state.step === 1 ? (
          <div className="flex flex-col items-center">
            <h2>Register for a free account</h2>
            <div className="flex items-center gap-5 p-4 m-4 border rounded-lg border-amber-200 bg-amber-100 text-amber-600">
              <SparklesIcon className="w-8 h-8 p-1 transition-colors rounded-full text-amber-600" />
              <div>
                <h3>Start now to activate your free trial of LinePilot Pro</h3>
                <p className="">
                  No credit card required - after 14 days your account will
                  revert to the free plan
                </p>
              </div>
            </div>
            <CreateUser
              invite={false}
              location={this.props.location}
              onSuccess={() => this.setState({ step: 2 })}
            />
          </div>
        ) : (
          <div className="flex flex-col">
            <h2 className="mt-6 text-2xl text-center">Start a Trial</h2>
            <div className="text-center">
              Get started with a free 14-day trial of Line Pilot. No credit card
              required.
            </div>
            <div className="max-w-xl mx-auto my-5">
              <OptionField
                options={[
                  {
                    value: "existing",
                    icon: <Enter className="w-auto h-12 mr-2 -ml-2" />,
                    label: "Join an existing account",
                    description:
                      "If your organisation has already started using LinePilot",
                  },
                  {
                    value: "new",
                    icon: <Rocket className="w-auto h-12 mr-2 -ml-2" />,
                    label: "Set up new trial",
                    description:
                      "If your organisation has never used LinePilot before",
                  },
                ]}
                value={this.state.trialOption}
                onChange={(value) => this.setState({ trialOption: value })}
              />
            </div>
            {this.state.trialOption === "existing" && (
              <div className="max-w-xl m-auto text-center ">
                <h3>Please ask for an invite</h3>
                <p>
                  You need an invite from a member of your team. If you&apos;ve
                  already had this, click the link in the email now. If not, ask
                  for an invite - check the support site for further
                  instructions.
                </p>
              </div>
            )}
            {this.state.trialOption === "new" && (
              <CreateOrganisation location={this.props.location} />
            )}
            {(!this.state.trialOption || this.state.trialOption === "new") && (
              <p className="max-w-xl m-auto mt-6 text-center">
                <h3>Trying to extend or reactivate a trial?</h3> Please get in
                touch with our support team instead - they are happy to help.
              </p>
            )}
          </div>
        )}
      </OnboardingWrapper>
    );
  }
}

export default Register;
