import React from "react";
import { Link } from "@reach/router";

import LPLogo from "../assets/images/LinePilot_logo.png";

import Session from "../components/Session";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import DropdownMenu from "../components/menus/MainMenuDropDown";
import { SparklesIcon } from "@heroicons/react/24/outline";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "loading",
      onboarding: 0,
      user: false,
      toggleMenu: false,
    };
  }
  componentDidMount = async () => {
    let user = await Session.getUserData();
    this.setState({ user });
  };

  render() {
    const isActive = ({ isCurrent }) => {
      if (isCurrent) {
        return {
          className:
            "flex items-center px-3 py-2 text-sm text-dark hover:text-primary",
        };
      } else {
        return {
          className:
            "flex items-center px-3 py-2 text-sm text-dark hover:text-primary",
        };
      }
    };

    return (
      <div className="w-full min-h-screen md:flex-row">
        <div className="fixed top-0 z-30 flex flex-col items-center w-full px-5 py-2 md:flex-row bg-gradient-to-b from-pale via-pale to-transparent">
          <a
            href="https://www.linepilot.co"
            className="relative items-center block mr-5 -ml-5"
          >
            <img
              src={LPLogo}
              className="w-auto h-20 md:h-24"
              alt="LinePilot Logo"
            />
          </a>
          <nav className="flex flex-row items-center justify-start flex-grow gap-10 text-sm">
            <Link getProps={isActive} to="/">
              My Templates
            </Link>

            <Link getProps={isActive} to="/logs">
              Logs
            </Link>

            {["free", "trial", "starter"].includes(
              this.state.user?.org?.plan,
            ) && (
              <Link
                getProps={isActive}
                to="/billing"
                className="flex items-center gap-1 transition-colors group"
              >
                <SparklesIcon className="w-6 h-6 p-1 mr-2 text-white transition-colors rounded-full bg-amber-500 group-hover:bg-amber-600" />
                <span className="text-amber-500 group-hover:text-amber-600">
                  Upgrade
                </span>
              </Link>
            )}

            {this.state.user?.org?.plan === "partner" && (
              <Link
                getProps={isActive}
                to="/billing"
                className="flex items-center gap-1 transition-colors group"
              >
                Plans & Pricing
              </Link>
            )}
            <a
              href="https://www.linepilot.co/support-resources"
              className="flex items-center gap-2"
            >
              Support and Resources
              <ArrowRightIcon className="w-4 h-4" />
            </a>
          </nav>

          <nav className="flex flex-row items-center justify-end flex-grow gap-10 text-sm">
            <DropdownMenu />
            <Link
              to="/login"
              onClick={() => Session.logout(this.props.location.pathname)}
              getProps={isActive}
            >
              Log Out
            </Link>
          </nav>
        </div>
        <div className="relative w-full min-h-screen">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Dashboard;
