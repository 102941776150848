import React from "react";
import OnboardingSidebar from "../components/blocks/OnboardingBlock.js";
import { motion } from "framer-motion";
import LPLogo from "../assets/images/LinePilot_logo.png";

const OnboardingWrapper = ({
  onboarding,
  updateOnboarding,
  children,
  skipSurvey,
}) => {
  return (
    <div className="relative w-full h-full min-h-screen md:flex-row">
      <div className="fixed top-0 left-0 w-screen h-screen bg-blend"></div>
      <div className="fixed top-0 z-20 flex flex-row items-center w-full px-5 py-2">
        <a
          href="https://www.linepilot.co"
          className="relative block mr-5 -ml-5"
        >
          <img
            src={LPLogo}
            className="w-auto h-20 md:h-24"
            alt="LinePilot Logo"
          />
        </a>
      </div>
      <div className="absolute z-10 w-full min-h-screen">
        <div className="flex-col items-center h-screen max-w-screen-xl mx-auto lg:flex lg:flex-row">
          <div className="fixed items-center flex-1 hidden h-screen p-5 lg:flex">
            <div className="flex flex-col gap-5">
              <OnboardingSidebar
                onboarding={onboarding}
                updateOnboarding={updateOnboarding}
                skipSurvey={skipSurvey}
              />
            </div>
          </div>
          <motion.div
            className="flex flex-col flex-1 lg:h-screen px-10 lg:px-0 lg:ml-[25rem] xl:ml-[30rem] justify-start"
            key="1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex flex-col items-center mx-5 my-12 tall:my-36">
              {children}
            </div>
          </motion.div>
          <div className="w-full p-5 lg:hidden"></div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingWrapper;
