import React from "react";

import { navigate } from "@reach/router";

import Message from "./modals/Message";
import Modal from "./modals/Modal";
import Config from "../config";
import fetchFromApi from "../utils/fetchFromApi";

class CreateTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      errorDetails: false,
      success: false,
      newTemplateName: "",
    };
  }
  componentDidMount = async () => {
    document.title = `Create a Template ${Config.titleSuffix}`;
  };

  async createTemplate() {
    try {
      this.setState({ loading: true });

      const path = "/template/create";
      const options = {
        method: "POST",
        body: JSON.stringify({
          name: this.state.newTemplateName || "Template 1",
        }),
      };

      const response = await fetchFromApi(
        path,
        this.props.location.pathname,
        options,
      );

      if (this.props.onboarding) {
        localStorage.setItem("isOnboarding", "complete");
      }

      navigate("/template/" + response.id + "?newTemplate=true");
    } catch (error) {
      console.log(error);
      this.setState({
        loading: false,
        error: true,
        errorDetails: {
          title: "Could not create Template",
          message:
            typeof error === "string"
              ? error
              : "We were unable to create your template. Please try again.",
        },
      });
    }
  }

  render() {
    return (
      <div className="flex flex-col items-center my-36">
        <Message
          open={this.state.loading}
          type="loading"
          title="Creating account..."
        />
        <Modal
          open={this.state?.error}
          type="error"
          title={this.state.errorDetails.title}
          message={this.state.errorDetails.message}
          setClose={() => {
            this.setState({ error: false }),
              setTimeout(() => this.setState({ errorDetails: false }), 500);
          }}
        />

        <h2>Create your first template</h2>
        <div className="max-w-2xl my-4 text-sm text-center">
          LinePilot works using templates to define which line items get added
          to your deals, and when. Templates keep your line items in sync, even
          if your deal is updated.
        </div>

        <div className="flex flex-col items-center w-full max-w-2xl gap-5 p-8 mt-4 card-ghost ">
          <div className="flex-1 min-w-[50%] group">
            <label htmlFor="newTemplateName" className="mb-1">
              Template Name
            </label>
            <input
              id="newTemplateName"
              className="w-full"
              type="text"
              defaultValue={`Template 1`}
              onChange={(e) =>
                this.setState({ newTemplateName: e.target.value })
              }
            />
          </div>
          <div className="">
            <button
              className="button-primary"
              onClick={() => this.createTemplate()}
            >
              Add New Template
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateTemplate;
