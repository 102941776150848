import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export default function ErrorState({
  h1 = "Error",
  message = "There was a problem processing your request",
  continueUrl = "/",
}) {
  return (
    <div className="flex flex-row items-center justify-center max-w-screen-xl min-h-screen mx-auto">
      <div className="flex flex-col items-center w-full max-w-sm text-center">
        <ExclamationCircleIcon
          className="flex-auto w-10 h-10 m-3 text-red-300"
          aria-hidden="true"
        />
        <span className="flex-auto">{h1}</span>
        <span
          className="flex-auto mt-2"
          dangerouslySetInnerHTML={{ __html: message }}
        ></span>
        <span className="mt-2">
          Continuing to have problems?{" "}
          <a href="https://www.linepilot.co">Reach out to the support team</a>
        </span>
        <span className="mt-10">
          <a href={continueUrl} className="button-secondary">
            Continue{" "}
          </a>
        </span>
      </div>
    </div>
  );
}
